<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";
import btnComponent from "@/components/btnComponent";
// import { filter } from 'vue/types/umd';
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "การนัดหมาย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    DatePicker,
    ChangeDate,
    btnComponent,
  },
  data() {
    return {
      // tableData: tableData,

      title: "การนัดหมาย",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
        },
        {
          text: "การนัดหมาย",
          active: true,
        },
      ],
      DateSearch: [],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [5, 25, 50, 100],
      filter: {
        nameTh: "",
        licensePlate: "",
        DateSearch:''
      },
      branchIdLC: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "apptId",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "apptCode",
          sortable: true,
          label: "รหัสนัดหมาย",
        },
        {
          key: "vehiclePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "customerName",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "apptDate",
          sortable: true,
          label: "วันที่",
        },
        {
          key: "apptTime",
          sortable: true,
          label: "เวลา",
        },
        {
          key: "customerMobilePhone",
          sortable: true,
          label: "เบอร์โทรศัพท์",
        },
        {
          key: "action",
          label: "",
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.rows.total;
  },
  created() {
    this.getLocalData();
    this.getData();
    this.getNow();
    // this.select()
  },
  methods: {
    // changeDate(date){
    //   // // console.log(date);
    //   const dateChange = new Date(date);
    //   const changeDate = dateChange.toLocaleDateString('th-TH', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     weekday: 'long',
    //   });
    //   return changeDate;
    // },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // // console.log(this.DateSearch);
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchIdLC = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // // console.log(this.localDataBranch);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/appointment", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: [this.branchIdLC],
            apptDate: this.filter.DateSearch,
            customerName : this.filter.nameTh, 
            vehiclePlate: this.filter.licensePlate
            // apptCode: 1,
            // apptDate: 2021-07-02,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
          // console.log(this.rows);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteVehicle(rows) {
      this.vehicleId = rows;
      this.overlayFlag = true;
      useNetw
        .delete("api/vehicle/delete", {
          data: {
            vehicleId: this.vehicleId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    popupModalPrintApptReport() {
      this.$refs["modalPrintflowSlot"].show();
    },

    getReportAppt() {
      this.loading = true;
      useNetw
        .get("api/appointment/print", {
          params: {
            branchId: this.branchIdLC,
            apptDate: this.DateSearch,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          this.linkPtintFlowslot = data.data.url;
          window.location = (data.data.url);
          this.$refs["modalPrintflowSlot"].hide();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูลรถในวันที่ " + this.DateSearch),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row mt-4">
                <div class="col-md-2">
                  <label class="d-inline align-items-center m-2 text-end">
                    ทะเบียน :
                    <b-form-input
                      v-model="filter.licensePlate"
                      type="search"
                      class="form-control"
                      @keyup.enter="handleSearch"
                    ></b-form-input>
                   :</label>
                </div>
                <div class="col-md-3">
                  <label class="d-inline align-items-center m-2">
                    ชื่อลูกค้า:
                    <b-form-input
                      v-model="filter.nameTh"
                      type="search"
                      class="form-control"
                      @keyup.enter="handleSearch"
                    ></b-form-input>
                   :</label>
                </div>
                <div class="col-md-2">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="birthDate"> :</label>วันที่

                    <date-picker
                      v-model="filter.DateSearch"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                    >
                    </date-picker>
                  </div>
                </div>

                <div class="col-md-5 text-end ">
                  <br />
                  <btnComponent @click="handleSearch" changeStyle="search">
                  </btnComponent
                  >&nbsp;
                  <b-button
                    class="btn"
                    variant="primary"
                    type="submit"
                    :to="{ name: 'appointmentform' }"
                  >
                    <i class="uil-plus"></i>
                    สร้าง </b-button
                  >&nbsp;
                  <b-button
                    class="btn"
                    variant="primary"
                    type="submit"
                    @click="popupModalPrintApptReport"
                  >
                    <i class="uil-print"></i>
                    พิมพ์
                  </b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                         :</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-3"></div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <tableData :fields="fields" :items="rows">
                <template #cell(apptDate)="rows">
                  <changeDate :date="rows.item.apptDate"></changeDate>
                </template>
                <template #cell(action)="rows">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        :to="{
                          name: 'appointmentformedit',
                          params: { id: rows.item.apptId },
                        }"
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-modal
      title="เอกสารรายงานการนัดหมาย"
      hide-footer
      size="sm"
      centered
      ref="modalPrintflowSlot"
    >
      <div class="row">
        <div class="col">
          <label for="amount"><code>*</code> วันที่  :</label>
          <date-picker
            v-model="DateSearch"
            :first-day-of-week="1"
            format="YYYY-MM-DD"
            value-type="format"
            lang="en"
          >
          </date-picker>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <a class="btn btn-primary" @click="getReportAppt()">
              พิมพ์เอกสาร
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
